import React from "react"
import PageTransition from "./components/elements/pageTransition"

// eslint-disable-next-line react/prop-types
export const wrapPageElement = ({ element, props }) => {
  return <PageTransition {...props}>{element}</PageTransition>
}

export const shouldUpdateScroll = () => {
  return true
}
