import React from "react"
import PropTypes from "prop-types"

import { HeaderProvider } from "./components/Header/context"
import { YearProvider } from "./components/Home/Artists/context"

export const wrapRootElement = ({ element }) => {
  return (
    <HeaderProvider>
      <YearProvider> {element} </YearProvider>
    </HeaderProvider>
  )
}

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}

export default wrapRootElement
