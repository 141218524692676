// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-culture-js": () => import("./../../../src/pages/culture.js" /* webpackChunkName: "component---src-pages-culture-js" */),
  "component---src-pages-entertainment-js": () => import("./../../../src/pages/entertainment.js" /* webpackChunkName: "component---src-pages-entertainment-js" */),
  "component---src-pages-getting-tattooed-js": () => import("./../../../src/pages/getting-tattooed.js" /* webpackChunkName: "component---src-pages-getting-tattooed-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketplace-js": () => import("./../../../src/pages/marketplace.js" /* webpackChunkName: "component---src-pages-marketplace-js" */),
  "component---src-pages-pr-js": () => import("./../../../src/pages/pr.js" /* webpackChunkName: "component---src-pages-pr-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

