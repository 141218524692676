import React, { createContext, PureComponent } from "react"

export const defaultHeaderContext = {
  isOpen: false,
  toggleOpen: () => {},
}

export const HeaderContext = createContext(defaultHeaderContext)

export class HeaderProvider extends PureComponent {
  state = {
    ...defaultHeaderContext,
    toggleOpen: () => {
      // console.log("I am being toggled")
      this.setState({ isOpen: !this.state.isOpen })
    },
  }

  render() {
    return (
      <HeaderContext.Provider value={this.state}>
        {this.props.children}
      </HeaderContext.Provider>
    )
  }
}
