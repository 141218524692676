import React, { createContext, PureComponent } from "react"

export const defaultYearContext = {
  updatedYear: "2024",
  updateYear: () => {},
}

export const ArtistYearContext = createContext(defaultYearContext)

export class YearProvider extends PureComponent {
  state = {
    ...defaultYearContext,
    updateYear: year => {
      this.setState({ updatedYear: year })
    },
  }

  render() {
    return (
      <ArtistYearContext.Provider value={this.state}>
        {this.props.children}
      </ArtistYearContext.Provider>
    )
  }
}
