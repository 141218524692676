/**
 * @flow
 */
import React, { useEffect, useContext } from "react"
import { ReactNode } from "react"

import { HeaderContext } from "../Header/context"

// type Props = {
//   children: ReactNode,
//   location: string,
// }

const PageTransition = ({ location, children }) => {
  const context = useContext(HeaderContext)

  useEffect(() => {
    if (context.isOpen) {
      context.toggleOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return <> {children} </>
}

export default PageTransition
